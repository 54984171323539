export const BeyondEMRConstants =
{
  EncryptionTokenKey: 'db27ffc6d8164381b074f95abbd0653bbfa40ee63a3143b29c3d0d747d7b3d2',
  EmailPattern: /^([a-zA-Z0-9_\-\.]+)@[a-zA-Z0-9-]+(\.[a-z0-9-]+)*(\.[a-zA-Z]{2,3})$/,
  LocalStorageAuthorizationToken: 'AuthToken',
  TokenPrefixName: 'Bearer ',
  DateSaveFormat: 'DD-MMM-YYYY',
  NewDateSaveFormat: 'MM-dd-yyyy',
  ImageString: 'data:image/png;base64,',
  DateDBFormat: 'MM/dd/yyyy',

  ResponseType: {
    PDF: 'application/pdf',
    HTML: 'text/html',
    Excel: 'application/vnd.ms-excel'
  },

  PaymentOption: {
    PayFor_Visit : 1,
    Pay_Custom : 2
  },

  STATUS: {
    Patient_Intake: 1,
    Patient_Pending: 2,
    Patient_Active: 3,
    Appointment_Pending: 4,
    Scheduled: 5,
    Checked_In: 6,
    Cancelled: 7,
    No_Show: 8,
    Open_Notes: 9,
    Co_Sign: 10,
    Signed: 11,
    Open_Superbill: 12,
    Review_Pending: 13,
    Checked_Out: 14,
    Draft: 15,
    In_progress: 16,
    Review: 17,
    Approved: 18,
    Rejected: 19,
    Unpayable: 20,
    All_Claims: 21,
    Ready_To_Submit_Claims: 22,
    Claim_Rejected: 23,
    Denials: 24,
    No_Response: 25,
    Pending_Insurance: 26,
    Pending_Patient: 27,
    Completed: 28,
    Transaction_Created: 29,
    Transaction_Updated: 30,
    Patient_Transfer: 31,
    Insurance_Transfer: 32,
    Billed: 33,
    Submit_Claim: 34,
    Receive_CH_Report: 35,
    ERA_Received: 36,
    Insurance_Adjustment: 37,
    Patient_Adjustment: 38,
    Insurance_Payment: 39,
    Patient_Payment: 40,
    Settled: 41,
    SelfPay_Transfer: 42,
    Submitted_Claim: 43,
    Evaluation_Completed: 44,
    Evaluation_Pending: 45,
    POC_Completed: 46,
    POC_Pending: 47,
    Claim_ERAPending: 48,
    PatientPOC_Met: 49,
    PatientPOC_InProgress: 50,
    PatientPOC_Notmeet: 51,
  },

  preferredContact: {
    Phone: 1,
    Mobile: 2,
  },
  PageType: {
    CREATE: 'create',
    EDIT: 'edit',
    VIEW: 'view',
  },
  ActionType: {
    ADD: 'add',
    EDIT: 'edit'
  },
  ActivityMaster: {
    Dashboard: 1,
    Create: 2,
    Edit: 3,
    View: 4,
    Delete: 5
  },
  ScreenTypeMaster: {
    Dashboard: 1,
    Master: 2,
    Transaction: 3,
    Reports: 4,
    Tools: 5,
    Profile: 6,
    Widgets: 7,
    Graph: 8,
    SuperAdmin: 9
  },
  QuestionType: {
    CheckBox: 'CheckBox',
    Yes_No: 'Yes_No',
    Multiselect: 'Multiselect',
    TextBox: 'TextBox',
    TextArea: 'TextArea',
    Dropdown: 'Dropdown',
    label: 'label',
  },
  Hispanic: [{ Id: 1, DisplayName: 'Yes' }, { Id: 2, DisplayName: 'No' }],
  ContactType: { Relation_Other: 7, Insurance_Address: 19, Emergency_Contact: 6, Patient: 5 },
  ResponsibleType: { Myself: 1, Emergency_Contact: 2, Other: 3 },
  HistoryType: [{ Id: 1, Title: 'Past Medical History (PMHx)' }, { Id: 2, Title: 'Family History (FHx)' }, { Id: 3, Title: 'Social History (SHx)' }, { Id: 4, Title: 'Development Milestones' }, { Id: 5, Title: 'Other Miscellaneous Information' }],
  ProviderDashboardMoreOptions: [{ Id: 1, Name: 'More Options', DisplayName: 'More Options', URL: '' }, { Id: 2, Name: 'Capture Charge', DisplayName: 'Capture Charge', URL: '' }, { Id: 3, Name: 'Collect Payment', DisplayName: 'Collect Payment', URL: '' }, { Id: 4, Name: 'Print Encounter Form', DisplayName: 'Print Encounter Form', URL: '' }, { Id: 5, Name: 'Edit Appointment', URL: 'Edit Appointment' }, { Id: 6, Name: 'Delete Appointment', DisplayName: 'Delete Appointment', URL: '' }],
  Status: [{ Id: 1, Name: 'Scheduled', URL: '' }, { Id: 4, Name: 'Checked In', URL: '' }, { Id: 5, Name: 'Checked Out', URL: '' }, { Id: 6, Name: 'Cancelled', URL: '' }, { Id: 7, Name: 'Reschedule', URL: '' }],
  Notes: [{ Id: 1, Name: 'Create Clinical Note', URL: '' }, { Id: 2, Name: 'Speech Therapy SOAP Note', URL: '' }, { Id: 3, Name: 'PT SOAP Note', URL: '' }, { Id: 4, Name: 'Occupational Therapy SOAP Note', URL: '' }],
  NoteStatus: [{ Id: 1, Name: "Pending", EnumName: 'Open_Notes' }, { Id: 2, Name: "Completed", EnumName: 'Notes_Completed' }],

  ProviderDashboard: [
    {
      Id: 0, Name: 'Priorities', EnumName: 'Priority', Count: 0, class: 'fas fa-star', class2: '', class3: '#5DA5DA', textColor: 'white', icon: ''
    },
    { Id: 5, Name: 'Scheduled', EnumName: 'Scheduled', Count: 0, class: 'fas fa-calendar-alt', class2: '', class3: '#ff869a', textColor: 'white', icon: '' },
    { Id: 6, Name: 'Checked In', EnumName: 'Checked_In', Count: 0, class: 'fas fa-calendar-check', class2: '', class3: '#FAA43A', textColor: 'white', icon: '' },
    { Id: 9, Name: 'Open Notes', EnumName: 'Open_Notes', Count: 0, class: 'fas fa-notes-medical', class2: '', class3: '#59e0c5', textColor: 'white', icon: '' },
    { Id: 12, Name: 'Super Bill', EnumName: 'Super_Bill', Count: 0, class: 'fas fa-receipt', class2: '', class3: '#B276B2', textColor: 'white', icon: '' },
    { Id: 14, Name: 'Finished', EnumName: 'Finished', Count: 0, class: 'fas fa-clipboard-check', class2: '', class3: '#F15854', textColor: 'white', icon: '' }
  ],

  EncounterDashboard: [
    { Id: 0, Name: 'Pending', EnumName: 'Priority', Count: 0, class: 'material-icons col-white font-30', class2: '', class3: '#5DA5DA', textColor: 'white', icon: 'priority_high' },
    { Id: 16, Name: 'In Progress', EnumName: 'In_Progress', Count: 0, class: 'material-icons col-white font-30', class2: '', class3: '#ff869a', textColor: 'white', icon: 'schedule' },
    { Id: 17, Name: 'Review', EnumName: 'Review', Count: 0, class: 'material-icons col-white font-30', class2: '', class3: '#FAA43A', textColor: 'white', icon: 'check_circle' },
    { Id: 18, Name: 'Approved', EnumName: 'Approved', Count: 0, class: 'material-icons col-white font-30', class2: '', class3: '#59e0c5', textColor: 'white', icon: 'note' },
    { Id: 19, Name: 'Rejected', EnumName: 'Rejected', Count: 0, class: 'material-icons col-white font-30', class2: '', class3: '#F15854', textColor: 'white', icon: 'book' },
    { Id: 20, Name: 'Denials', EnumName: 'Unpayable', Count: 0, class: 'material-icons col-white font-30', class2: '', class3: '#B276B2', textColor: 'white', icon: 'payment' }
  ],

  Role: { Admin: 1, Provider: 2, Referring_Provider: 3, Trainee_Provider: 4, Branch_Admin: 5, Biller: 6, Clinical_Assistant: 7, Office_Staff: 8, Patient: 9 },

  Adjustmenttype: {
    Courtesy_Adjustment: 1,
    No_Authorization_Adjustment: 2,
    Bad_Debt_Write_off: 3,
    Miscellaneous_Write_off: 4,
    Note: 5,
    Previous_ClaimBalance: 6
  },

  Attachmenttype: {
    Authorization: 5,
    Demographics: 6,
    Insurance_Card: 7,
    Medical_Report: 8,
    Eligibility: 9,
    Prescriptions: 10,
    Others: 11
  },

  Showonly: [
    { Id: 1, Type: "Open" },
    { Id: 2, Type: "All" },
    { Id: 3, Type: "Selected" },
    { Id: 4, Type: "Settled" },
    { Id: 5, Type: "Patient Responsibility" },
  ],

  PaymentMethod: {
    Selfpay: 1,
    Insurance: 3,
    Others: 4
  },

  PaymentType: {
    Check: 1,
    Credit_Card: 2,
    Electronic_Fund_Transfer: 3,
    Cash: 4,
    Other: 5,
    Unknown: 6
  },

  PatientWarning: { Warning: 'Please fill the required fields to continue.' },
  ClaimStatus: [{ Id: 1, Name: " All_Items" }, { Id: 2, Name: "Open_Items" }, { Id: 3, Name: "Settled_Items" }],

  DecimalFormat: '1.2-2',

  holidayType: {
    Federal_Holiday: 1,
    Government_Holiday: 2,
    Others: 3
  },

  UserRole: {
    Admin: "Admin",
    Patient: "Patient"
  },

  Transition: {
    Person: "Person",
    Focus: "Focus",
    Accuracy: "Accuracy",
    Assistance: "Assistance",
    Date: "Date",
    Rating: "Rating"
  }

}
