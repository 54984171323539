import { Injectable } from '@angular/core';
import { throwError, Observable, of } from 'rxjs';

import { ActivatedRoute, Router } from '@angular/router';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';

import { catchError, map } from 'rxjs/operators';
import { CommonService } from './common.service';
import { AuthTokenHelper } from '../helpers/auth-token-helper';
import * as jwt_decode from 'jwt-decode'
import { BeyondEMRConstants } from '../helpers/beyondemrconstants';
import { saveAs } from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { BeyondEMRHelper } from '@helpers/beyondemr-helper';

@Injectable()
export class HttpBaseService {

    constructor(
      protected http: HttpClient,
      private service: CommonService,
      private route: ActivatedRoute,
      private authHelper?: AuthTokenHelper,
      private router?: Router,
      private spinner?: NgxSpinnerService,
      private helper?: BeyondEMRHelper
    ) { }

    getAPIURL() {
      try {
            const getAPIURL: object = this.service.getApiURL();
            if (getAPIURL && Object.keys('getAPIURL').length > 0) {
                return of(getAPIURL);
            }
            return this.get('assets/config.json', null, false).pipe(map((response: Response) => {
                const result = response as any;
                this.service.setApiURL(result);
                return result;
            }, catchError(err => throwError(err))));
        } catch (ex) {
            throw ex;
        }
  } 

  getDataFromServer<T>(URL: string): Observable<T> {
    let header = this.getHeader();
    
    return this.http.get<T>((URL), { headers: header }).pipe(catchError(err => throwError(err)));
  }

   get(url: string, param: object = {}, hasHeader: boolean = true): Observable<any> {
    try {
      let header = null;
      if (hasHeader) {
        header = this.getHeader();
      }
      const params: HttpParams = this.constructQueryStringParams(param);
      return this.http.get(`${url}`, { headers: header, params }).pipe(catchError(err => throwError(err)));
    } catch (ex) {
      throw ex;
    }
  }

  public appendFormData(items): FormData {
    const formData = new FormData();    
    formData.set('item', JSON.stringify(items));
    return formData;
  }

  put(url: string, item: object | FormData, hasHeader: boolean = true): Observable<any> {
    try {
      let header = null;
      if (hasHeader) {
        header = this.getHeader();
      }
      let body = this.appendFormData(item);
      return this.http.put(`${url}`, body, { headers: header }).pipe(catchError(err => throwError(err)));
    } catch (ex) {
      throw ex;
    }
  }

  post(url: string, item: object | FormData = {}, hasHeader: boolean = true): Observable<any> {
    try {
      let header = null;
      if (hasHeader) {
        header = this.getHeader();
      }
      let body = this.appendFormData(item);
      return this.http.post(`${url}`,body, { headers: header, observe: 'response' }).pipe(
        map(res => res.body),
        catchError(err => throwError(err))
      );
    } catch (ex) {
      throw ex;
    }
  }

  postWithFile(url: string, item: object | FormData = {}, hasHeader: boolean = true): Observable<any> {
    try {
      let header = null;
      if (hasHeader) {
        header = this.getHeader();
      }
      let body = item
      return this.http.post(`${url}`, body, { headers: header, observe: 'response' }).pipe(
        map(res => res.body),
        catchError(err => throwError(err))
      );
    } catch (ex) {
      throw ex;
    }
  }

  postWithHeader(url: string, item: object | FormData = {}, headers?: HttpHeaders): Observable<any> {
    try {
      const body = item && Object.keys(item).length > 0 ? item : {};
      return this.http.post(`${url}`, body, { headers: headers, observe: 'response' }).pipe(
        map(res => res.body),
        catchError(err => throwError(err))
      );
    } catch (ex) {
      throw ex;
    }
  }

  postWithParameter(url: string, item: object | FormData = {}): Observable<any> {
    try {
      const body = item && Object.keys(item).length > 0 ? item : {};
      return this.http.post(`${url}`, body, { observe: 'response' }).pipe(
        map(res => res.body),
        catchError(err => throwError(err))
      );
    } catch (ex) {
      throw ex;
    }
  }

  reportSearch(url: string, fileName: string, postData?: any, callback?: any, hasHeader: boolean = true): any {
    try {
      let formData = new FormData();
      formData.append('item', JSON.stringify(postData));
      let header = null;
      if (hasHeader) {
        header = this.getHeader();
      }
      return this.http.post((`${url}`), formData, { headers: header, responseType: 'blob' }).subscribe(
        response => {         
          if (response.type == BeyondEMRConstants.ResponseType.Excel) {
            const blob = new Blob([response], { type: BeyondEMRConstants.ResponseType.Excel });
            const file = new File([blob], fileName, { type: BeyondEMRConstants.ResponseType.Excel });
            saveAs(file);
          }
          else {
            const blob = new Blob([response], { type: BeyondEMRConstants.ResponseType.PDF });
            // const file = new File([blob], fileName, { type: BeyondEMRConstants.ResponseType.PDF });
            var url = URL.createObjectURL(blob);
            var encodedURL = url.split("/").join("_");
            window.open('/report/download/' + encodedURL + '/' + fileName);       
          }
          this.spinner.hide();
        },
        error => {
          this.helper.showNotification('No record found', 'snackbar-danger');
          this.spinner.hide();
        });
    } catch(ex) {
      throw ex;
    }
    }

  postBlob(url: string, item: object | FormData = {}, hasHeader: boolean = true): Observable<Blob> {
      try {
        let header = null;
        if (hasHeader) {
          header = this.getHeader();
        }
        const body = item && Object.keys(item).length > 0 ? item : {};
        return this.http.post(`${url}`, body, { headers: header, responseType: 'blob' }).pipe(
                map(res => res),
                catchError(err => throwError(err))
            );
        } catch (ex) {
            throw ex;
        }
    }

  delete(url: string, param: object = {}): Observable<any> {
      try {
        let header = this.getHeader();
        const params: HttpParams = this.constructQueryStringParams(param);
        return this.http.get(`${url}`, { headers: header, params }).pipe(catchError(err => throwError(err)));
      } catch (ex) {
        throw ex;
      }
    }

  getHeader() {
    let token = this.authHelper.GetAuthorizationTokenValue();
    let tokenExpired = this.IsTokenExpired(token);

    if (tokenExpired) {
      this.router.navigate(['/authentication/signin']);
    }
    else {
      const header = new HttpHeaders()
        .set('Authorization', token);

      return header;
    }    
  }

    constructQueryStringParams(item: object) {
        try {
            let params: HttpParams = new HttpParams();
            if (item && Object.keys(item).length > 0) {
                Object.keys(item).map(key => {
                    params = params.append(key, item[key]);
                });
                return params;
            } else {
                return params;
            }
        } catch (ex) {
            throw ex;
        }
  }

  public IsTokenExpired(token?: any): boolean {
    if (!token) {
      token = new AuthTokenHelper().GetAuthorizationTokenValue();
    }
    let tokenResult = this.getDecodedAccessToken(token);
    let result: any;
    if (token) {
      const date = this.getTokenExpirationDate(token);
      result = !(date.valueOf() > new Date().valueOf());
    }
    if (result || result === undefined) {
      return true;
    }
    else {
      return false;
    }
  }  

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);

    if (decoded.exp === undefined) return null;

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }
}
