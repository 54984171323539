import { DOCUMENT } from '@angular/common';
import { Component, Inject, ElementRef, OnInit, Renderer2, HostListener } from '@angular/core';
import { AuthTokenHelper } from '../../helpers/auth-token-helper';
import { AuthService } from '../../service/auth.sevice';
import { UserMenuAuth } from '../../helpers/usermenuauth';
import * as _ from 'underscore';
import { RouteInfo } from './sidebar.metadata';
import { HttpBaseService } from '../../service/http-base.service';
import { BeyondEMRConstants } from '@helpers/beyondemrconstants';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.sass']
})

export class SidebarComponent implements OnInit {
	public sidebarItems: any[];
	showMenu = '';
	showSubMenu = '';
	userName = '';
	roleName = '';
	public innerHeight: any;
	public bodyTag: any;
	listMaxHeight: string;
	listMaxWidth: string;
	token: string;
	headerHeight = 60;
	profileImage: any;
	showProfileImage: boolean = false;
	apiURL: string;
	detectPatient: string;

	constructor(
		@Inject(DOCUMENT) private document: Document,
		private renderer: Renderer2,
		public elementRef: ElementRef,
		private httpService: HttpBaseService,
		private authHelper?: AuthTokenHelper,
		private authService?: AuthService,
		private usermenu?: UserMenuAuth
	) {
		this.getSideBarMenu();
		this.token = this.authHelper.GetAuthorizationTokenValue();
	}
	@HostListener('window:resize', ['$event'])
	windowResizecall(event) {
		this.setMenuHeight();
		this.checkStatuForResize(false);
	}

	callMenuToggle(event: any, element: any) {
		if (element === this.showMenu) {
			this.showMenu = '0';
		} else {
			this.showMenu = element;
		}
		const hasClass = event.target.classList.contains('toggled');
		if (hasClass) {
			this.renderer.removeClass(event.target, 'toggled');
		} else {
			this.renderer.addClass(event.target, 'toggled');
		}
	}
	callSubMenuToggle(element: any) {
		if (element === this.showSubMenu) {
			this.showSubMenu = '0';
		} else {
			this.showSubMenu = element;
		}
	}
	ngOnInit() {
		let tokenResult = this.authService.getDecodedAccessToken(this.token);
		this.detectPatient = this.authService.getDecodedAccessToken(this.token).UserRole;

		this.userName = tokenResult ? tokenResult["DisplayName"] : "DR. Marcus Neal";
		this.roleName = tokenResult ? tokenResult["UserRole"] : "Owner / Director";

		this.initLeftSidebar();
		this.bodyTag = this.document.body;
		this.httpService.getAPIURL().subscribe(url => {
			this.apiURL = `${url.API_URL}`
			this.httpService.get(this.apiURL + 'userprofile/ViewUser/' + tokenResult['http://schemas.microsoft.com/ws/2008/06/identity/claims/primarysid'], null, true).subscribe(res => {
				if (res) {
					let data = res.Entity;
          this.profileImage = data.UserImage?.length > 0 ? BeyondEMRConstants.ImageString + data.UserImage : null;
          if (!this.profileImage) {
            this.showProfileImage = true;
          }
				}
			});
		});
	}

	getSideBarMenu() {
		let menu = this.usermenu.GetUserMenu();
		let dashboardMenu = menu.filter(item => item.IsMenuLink == true && item.HasAccess == true);
		let group = _.groupBy(dashboardMenu, 'ScreenTypeId');
		let result = new Array<RouteInfo>();

		_.each(group, function (item) {
			let groupData = new RouteInfo();
			let parentItem = _.first(item);
			groupData.title = parentItem?.ScreenTypeName;
			groupData.icon = parentItem?.Icon ?? 'fas fa-cogs';
			groupData.class = 'menu-toggle';
			groupData.groupTitle = false;
			groupData.path = '';


			let menuData = new Array<RouteInfo>();
			if (item && item?.length > 0) {
				_.each(item, function (item) {
					let data = new RouteInfo();
					data.title = item?.Title;
					data.class = 'ml-menu';
					data.groupTitle = false;
					data.path = item?.Path;
					data.submenu = [];
					menuData.push(data);
				})
			}

			groupData.submenu = menuData;

			result.push(groupData);
		});

		this.sidebarItems = result;
	}

	initLeftSidebar() {
		const _this = this;
		// Set menu height
		_this.setMenuHeight();
		_this.checkStatuForResize(true);
		// Set Waves
		// Waves.attach('.menu .list a', ['waves-block']);
		// Waves.init();
	}
	setMenuHeight() {
		this.innerHeight = window.innerHeight;
		const height = this.innerHeight - this.headerHeight;
		this.listMaxHeight = height + '';
		this.listMaxWidth = '500px';
	}
	isOpen() {
		return this.bodyTag.classList.contains('overlay-open');
	}
	checkStatuForResize(firstTime) {
		if (window.innerWidth < 1170) {
			this.renderer.addClass(this.document.body, 'ls-closed');
		} else {
			this.renderer.removeClass(this.document.body, 'ls-closed');
		}
	}
	mouseHover(e) {
		const body = this.elementRef.nativeElement.closest('body');
		if (body.classList.contains('submenu-closed')) {
			this.renderer.addClass(this.document.body, 'side-closed-hover');
			this.renderer.removeClass(this.document.body, 'submenu-closed');
		}
	}
	mouseOut(e) {
		const body = this.elementRef.nativeElement.closest('body');
		if (body.classList.contains('side-closed-hover')) {
			this.renderer.removeClass(this.document.body, 'side-closed-hover');
			this.renderer.addClass(this.document.body, 'submenu-closed');
		}
	}
}
